@import '../../styles/vars';

.user-form {
  .form {
    @extend %paper-like;
    width: 380px;
    padding: 40px 40px;
    margin: 60px auto 0;
    @media (max-width: 600px) {
      width: 100%;
      padding: 40px 20px;
      margin: 0;
      border: none;
    }
    header {
      .logo {
        display: block;
        width: 64px;
        height: 64px;
        margin: 0 auto;
      }
      h1 {
        margin: 0;
        color: #999;
        text-align: center;
        line-height: 64px;
        font-family: Palatino;
      }
    }
    .form-content {
      margin-top: 20px;
    }
    .form-control {
      width: 100%;
      margin: 5px 0;
    }
    .tips {
      font-size: 14px;
      color: #757575;
    }
  }
  .footer {
    margin: 10px 16px;
    text-align: center;
    color: #999;
    .info {
      text-align: center;
    }
  }
  .privacy-policy {
    text-decoration: underline;
    font-weight: bolder;
    cursor: pointer;
    
    &:hover {
      color: #38445e;
    }
  }
}

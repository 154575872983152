.tab-view {
  position: relative;
  width: 100%;
  height: 100%;
  .tabs {
    margin: 0;
    padding: 0;
    background: #f3f3f3;
    line-height: 26px;
    width: 100%;
    height: 26;
    color: #444;
    font-size: 13px;
    font-weight: normal;
    &::before {
      content: "";
      display: block;
      position: absolute;
      border-bottom: 1px solid #cccccc;
      width: 100%;
      height: 0;
      top: 27px;
    }
    li {
      display: inline-block;
      padding: 0 10px;
      &:hover {
        background: #eaeaea;
      }
      &.selected {
        border-bottom: 2px solid #61a6f2;
      }
    }
  }
  .content {
    position: absolute;
    top: 28px;
    bottom: 0;
    width: 100%;
    overflow: auto;
  }
}

$label-color: #085;
$prop-color: #05a;
$entity-color: #a11;
$error-color: #c03134;

a {
  text-decoration: none;
  cursor: pointer;
  color: #2196f3;
}

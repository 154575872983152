.editor-wrapper {
  .ql-editor {
    height: inherit;
    max-height: inherit;
    overflow-y: inherit;
  }
  .ql-toolbar.ql-snow {
    border: none;
    text-align: center;
    position: sticky;
    top: 0;
    z-index: 1;
    background-color: rgba(255,255,255,0.8);
    box-shadow:
      0px 3px 5px -1px rgba(0,0,0,0.2),
      0px 6px 10px 0px rgba(0,0,0,0.14),
      0px 1px 18px 0px rgba(0,0,0,0.12)
    ;
  }
}

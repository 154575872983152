.dossier {
  position: fixed;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

.ondrag {
  background-color: #fff;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
}

/*@font-face {
  font-family: myFirstFont;
  src: url(https://fonts.googleapis.com/css?family=Roboto:300,400,500);
}

body {
  font-family: myFirstFont !important;
}*/

.gm-style-iw-d {
  max-height: 200px !important;
  overflow: hidden !important;
  overflow-y: auto !important;
  opacity: 0.940;
}

.info-card:last-child {
  margin-bottom: 8px !important;
}

.info-card .title {
  padding: 8px 16px;
  background-color: #add9d6;
  font-size: 16px;
  font-weight: bold;
}

.info-card .desc {
  padding: 8px 16px;
  font-size: 16px;
}

.info-card .date {
  padding: 8px 16px;
  font-size: 14px;
  font-weight: bold;
}

.gm-style-iw.gm-style-iw-c {
  border: 2px solid #add9d6 !important;
  border-radius: 0 !important;
  background-color: #e6f0ef !important;
  color: #222 !important;
  opacity: 0.9;
}


.gm-style .gm-style-iw-t::after {
  background: #e6f0ef !important;
  z-index: -99 !important;
}

.gm-style .gm-style-iw-c {
  padding: 0 !important;
}


body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding-top: constant(safe-area-inset-top);
  padding-top: env(safe-area-inset-top); }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace; }


a {
  text-decoration: none;
  cursor: pointer;
  color: #2196f3; }

.dashboard {
  color: #333;
  position: relative; }
  .dashboard header {
    position: relative; }
    .dashboard header .logo img {
      height: 75px; }
    .dashboard header .actions {
      position: absolute;
      top: 0;
      right: 0; }
  .dashboard .main {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: space-between;
            justify-content: space-between; }
  .dashboard .quick-actions {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-around;
            justify-content: space-around;
    margin: 40px auto;
    width: 360px; }
  .dashboard footer {
    bottom: 10px;
    text-align: center; }
    .dashboard footer .copyright {
      color: #999; }

.loading {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%); }

.lds-facebook {
  display: inline-block;
  position: absolute;
  width: 80px;
  height: 80px; }
  .lds-facebook div {
    display: inline-block;
    position: absolute;
    left: 8px;
    width: 16px;
    background: #fff;
    -webkit-animation: lds-facebook 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
            animation: lds-facebook 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite; }
    .lds-facebook div:nth-child(1) {
      left: 8px;
      -webkit-animation-delay: -0.24s;
              animation-delay: -0.24s; }
    .lds-facebook div:nth-child(2) {
      left: 32px;
      -webkit-animation-delay: -0.12s;
              animation-delay: -0.12s; }
    .lds-facebook div:nth-child(3) {
      left: 56px;
      -webkit-animation-delay: 0;
              animation-delay: 0; }

@-webkit-keyframes lds-facebook {
  0% {
    top: 8px;
    height: 64px; }
  50%, 100% {
    top: 24px;
    height: 32px; } }

@keyframes lds-facebook {
  0% {
    top: 8px;
    height: 64px; }
  50%, 100% {
    top: 24px;
    height: 32px; } }

.lds-ring {
  display: inline-block;
  position: absolute;
  width: 64px;
  height: 64px; }
  .lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 51px;
    height: 51px;
    margin: 6px;
    border: 6px solid #fff;
    border-radius: 50%;
    -webkit-animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
            animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #fff transparent transparent transparent; }
  .lds-ring div:nth-child(1) {
    -webkit-animation-delay: -0.45s;
            animation-delay: -0.45s; }
  .lds-ring div:nth-child(2) {
    -webkit-animation-delay: -0.3s;
            animation-delay: -0.3s; }
  .lds-ring div:nth-child(3) {
    -webkit-animation-delay: -0.15s;
            animation-delay: -0.15s; }

@-webkit-keyframes lds-ring {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes lds-ring {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

.dossier {
  position: fixed;
  width: 100vw;
  height: 100vh;
  overflow: hidden; }

.ondrag {
  background-color: #fff;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2); }

.ql-container.ql-snow .ql-editor, .page-content {
  font-size: 16px;
  font-family: "Times New Roman", Arial, Helvetica, "Source Han Serif", "STSong", "NSimSun", "SimSun", "STFangsong", "FangSong", "FangSong_GB2312"; }
  .ql-container.ql-snow .ql-editor strong, .page-content strong {
    font-weight: 700; }
  .ql-container.ql-snow .ql-editor h1, .page-content h1 {
    font-size: 20px;
    line-height: 1.5; }
  .ql-container.ql-snow .ql-editor h2, .page-content h2 {
    font-size: 18px;
    line-height: 1.5; }
  .ql-container.ql-snow .ql-editor h3, .page-content h3 {
    font-size: 16px;
    line-height: 1.5; }
  .ql-container.ql-snow .ql-editor p, .page-content p {
    font-size: 16px;
    line-height: 32px; }
  .ql-container.ql-snow .ql-editor .quill-variable, .page-content .quill-variable {
    color: #f44336;
    -webkit-text-decoration-line: underline;
            text-decoration-line: underline;
    -webkit-text-decoration-style: dotted;
            text-decoration-style: dotted; }
    .ql-container.ql-snow .ql-editor .quill-variable.filled, .page-content .quill-variable.filled {
      color: inherit;
      -webkit-text-decoration-color: #ccc;
              text-decoration-color: #ccc; }
  .ql-container.ql-snow .ql-editor table, .page-content table {
    border-collapse: collapse;
    border-spacing: 0px;
    width: 100%; }
  .ql-container.ql-snow .ql-editor th,
  .ql-container.ql-snow .ql-editor td, .page-content th,
  .page-content td {
    padding: 5px;
    border: 1px solid black;
    word-break: break-all; }
  .ql-container.ql-snow .ql-editor tr, .page-content tr {
    line-height: 1.5; }
  .ql-container.ql-snow .ql-editor td:empty:after, .page-content td:empty:after {
    content: "\A0"; }

.editor-wrapper .page-content {
  height: auto; }

.ql-container {
  box-sizing: border-box;
  font-family: Helvetica, Arial, sans-serif;
  font-size: 13px;
  height: 100%;
  margin: 0px;
  position: relative; }

.ql-clipboard {
  left: -100000px;
  height: 1px;
  overflow-y: hidden;
  position: absolute;
  top: 50%; }

.ql-clipboard p {
  margin: 0;
  padding: 0; }

.ql-editor {
  box-sizing: border-box;
  cursor: text;
  line-height: 1.42;
  height: 100%;
  outline: none;
  overflow-y: auto;
  padding: 12px 15px;
  tab-size: 4;
  -moz-tab-size: 4;
  text-align: left;
  white-space: pre-wrap;
  word-wrap: break-word; }

.ql-editor p,
.ql-editor ol,
.ql-editor ul,
.ql-editor pre,
.ql-editor blockquote,
.ql-editor h1,
.ql-editor h2,
.ql-editor h3,
.ql-editor h4,
.ql-editor h5,
.ql-editor h6 {
  margin: 0;
  padding: 0;
  counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9; }

.ql-editor ol,
.ql-editor ul {
  padding-left: 1.5em; }

.ql-editor ol > li,
.ql-editor ul > li {
  list-style-type: none; }

.ql-editor ul > li::before {
  content: '\25CF'; }

.ql-editor li::before {
  display: inline-block;
  margin-right: 0.3em;
  text-align: right;
  white-space: nowrap;
  width: 1.2em; }

.ql-editor li:not(.ql-direction-rtl)::before {
  margin-left: -1.5em; }

.ql-editor ol li,
.ql-editor ul li {
  padding-left: 1.5em; }

.ql-editor ol li {
  counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
  counter-increment: list-num; }

.ql-editor ol li:before {
  content: counter(list-num, decimal) ". "; }

.ql-editor ol li.ql-indent-1 {
  counter-increment: list-1; }

.ql-editor ol li.ql-indent-1:before {
  content: counter(list-1, lower-alpha) ". "; }

.ql-editor ol li.ql-indent-1 {
  counter-reset: list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9; }

.ql-editor ol li.ql-indent-2 {
  counter-increment: list-2; }

.ql-editor ol li.ql-indent-2:before {
  content: counter(list-2, lower-roman) ". "; }

.ql-editor ol li.ql-indent-2 {
  counter-reset: list-3 list-4 list-5 list-6 list-7 list-8 list-9; }

.ql-editor ol li.ql-indent-3 {
  counter-increment: list-3; }

.ql-editor ol li.ql-indent-3:before {
  content: counter(list-3, decimal) ". "; }

.ql-editor ol li.ql-indent-3 {
  counter-reset: list-4 list-5 list-6 list-7 list-8 list-9; }

.ql-editor ol li.ql-indent-4 {
  counter-increment: list-4; }

.ql-editor ol li.ql-indent-4:before {
  content: counter(list-4, lower-alpha) ". "; }

.ql-editor ol li.ql-indent-4 {
  counter-reset: list-5 list-6 list-7 list-8 list-9; }

.ql-editor ol li.ql-indent-5 {
  counter-increment: list-5; }

.ql-editor ol li.ql-indent-5:before {
  content: counter(list-5, lower-roman) ". "; }

.ql-editor ol li.ql-indent-5 {
  counter-reset: list-6 list-7 list-8 list-9; }

.ql-editor ol li.ql-indent-6 {
  counter-increment: list-6; }

.ql-editor ol li.ql-indent-6:before {
  content: counter(list-6, decimal) ". "; }

.ql-editor ol li.ql-indent-6 {
  counter-reset: list-7 list-8 list-9; }

.ql-editor ol li.ql-indent-7 {
  counter-increment: list-7; }

.ql-editor ol li.ql-indent-7:before {
  content: counter(list-7, lower-alpha) ". "; }

.ql-editor ol li.ql-indent-7 {
  counter-reset: list-8 list-9; }

.ql-editor ol li.ql-indent-8 {
  counter-increment: list-8; }

.ql-editor ol li.ql-indent-8:before {
  content: counter(list-8, lower-roman) ". "; }

.ql-editor ol li.ql-indent-8 {
  counter-reset: list-9; }

.ql-editor ol li.ql-indent-9 {
  counter-increment: list-9; }

.ql-editor ol li.ql-indent-9:before {
  content: counter(list-9, decimal) ". "; }

.ql-editor .ql-indent-1:not(.ql-direction-rtl) {
  padding-left: 3em; }

.ql-editor li.ql-indent-1:not(.ql-direction-rtl) {
  padding-left: 4.5em; }

.ql-editor .ql-indent-1.ql-direction-rtl.ql-align-right {
  padding-right: 3em; }

.ql-editor li.ql-indent-1.ql-direction-rtl.ql-align-right {
  padding-right: 4.5em; }

.ql-editor .ql-indent-2:not(.ql-direction-rtl) {
  padding-left: 6em; }

.ql-editor li.ql-indent-2:not(.ql-direction-rtl) {
  padding-left: 7.5em; }

.ql-editor .ql-indent-2.ql-direction-rtl.ql-align-right {
  padding-right: 6em; }

.ql-editor li.ql-indent-2.ql-direction-rtl.ql-align-right {
  padding-right: 7.5em; }

.ql-editor .ql-indent-3:not(.ql-direction-rtl) {
  padding-left: 9em; }

.ql-editor li.ql-indent-3:not(.ql-direction-rtl) {
  padding-left: 10.5em; }

.ql-editor .ql-indent-3.ql-direction-rtl.ql-align-right {
  padding-right: 9em; }

.ql-editor li.ql-indent-3.ql-direction-rtl.ql-align-right {
  padding-right: 10.5em; }

.ql-editor .ql-indent-4:not(.ql-direction-rtl) {
  padding-left: 12em; }

.ql-editor li.ql-indent-4:not(.ql-direction-rtl) {
  padding-left: 13.5em; }

.ql-editor .ql-indent-4.ql-direction-rtl.ql-align-right {
  padding-right: 12em; }

.ql-editor li.ql-indent-4.ql-direction-rtl.ql-align-right {
  padding-right: 13.5em; }

.ql-editor .ql-indent-5:not(.ql-direction-rtl) {
  padding-left: 15em; }

.ql-editor li.ql-indent-5:not(.ql-direction-rtl) {
  padding-left: 16.5em; }

.ql-editor .ql-indent-5.ql-direction-rtl.ql-align-right {
  padding-right: 15em; }

.ql-editor li.ql-indent-5.ql-direction-rtl.ql-align-right {
  padding-right: 16.5em; }

.ql-editor .ql-indent-6:not(.ql-direction-rtl) {
  padding-left: 18em; }

.ql-editor li.ql-indent-6:not(.ql-direction-rtl) {
  padding-left: 19.5em; }

.ql-editor .ql-indent-6.ql-direction-rtl.ql-align-right {
  padding-right: 18em; }

.ql-editor li.ql-indent-6.ql-direction-rtl.ql-align-right {
  padding-right: 19.5em; }

.ql-editor .ql-indent-7:not(.ql-direction-rtl) {
  padding-left: 21em; }

.ql-editor li.ql-indent-7:not(.ql-direction-rtl) {
  padding-left: 22.5em; }

.ql-editor .ql-indent-7.ql-direction-rtl.ql-align-right {
  padding-right: 21em; }

.ql-editor li.ql-indent-7.ql-direction-rtl.ql-align-right {
  padding-right: 22.5em; }

.ql-editor .ql-indent-8:not(.ql-direction-rtl) {
  padding-left: 24em; }

.ql-editor li.ql-indent-8:not(.ql-direction-rtl) {
  padding-left: 25.5em; }

.ql-editor .ql-indent-8.ql-direction-rtl.ql-align-right {
  padding-right: 24em; }

.ql-editor li.ql-indent-8.ql-direction-rtl.ql-align-right {
  padding-right: 25.5em; }

.ql-editor .ql-indent-9:not(.ql-direction-rtl) {
  padding-left: 27em; }

.ql-editor li.ql-indent-9:not(.ql-direction-rtl) {
  padding-left: 28.5em; }

.ql-editor .ql-indent-9.ql-direction-rtl.ql-align-right {
  padding-right: 27em; }

.ql-editor li.ql-indent-9.ql-direction-rtl.ql-align-right {
  padding-right: 28.5em; }

.ql-editor .ql-video {
  display: block;
  max-width: 100%; }

.ql-editor .ql-video.ql-align-center {
  margin: 0 auto; }

.ql-editor .ql-video.ql-align-right {
  margin: 0 0 0 auto; }

.ql-editor .ql-bg-black {
  background-color: #000; }

.ql-editor .ql-bg-red {
  background-color: #e60000; }

.ql-editor .ql-bg-orange {
  background-color: #f90; }

.ql-editor .ql-bg-yellow {
  background-color: #ff0; }

.ql-editor .ql-bg-green {
  background-color: #008a00; }

.ql-editor .ql-bg-blue {
  background-color: #06c; }

.ql-editor .ql-bg-purple {
  background-color: #93f; }

.ql-editor .ql-color-white {
  color: #fff; }

.ql-editor .ql-color-red {
  color: #e60000; }

.ql-editor .ql-color-orange {
  color: #f90; }

.ql-editor .ql-color-yellow {
  color: #ff0; }

.ql-editor .ql-color-green {
  color: #008a00; }

.ql-editor .ql-color-blue {
  color: #06c; }

.ql-editor .ql-color-purple {
  color: #93f; }

.ql-editor .ql-font-serif {
  font-family: Georgia, Times New Roman, serif; }

.ql-editor .ql-font-monospace {
  font-family: Monaco, Courier New, monospace; }

.ql-editor .ql-size-small {
  font-size: 0.75em; }

.ql-editor .ql-size-large {
  font-size: 1.5em; }

.ql-editor .ql-size-huge {
  font-size: 2.5em; }

.ql-editor .ql-direction-rtl {
  direction: rtl;
  text-align: inherit; }

.ql-editor .ql-align-center {
  text-align: center; }

.ql-editor .ql-align-justify {
  text-align: justify; }

.ql-editor .ql-align-right {
  text-align: right; }

.ql-editor.ql-blank::before {
  color: rgba(0, 0, 0, 0.6);
  content: attr(data-placeholder);
  font-style: italic;
  pointer-events: none;
  position: absolute; }

.ql-snow.ql-toolbar:after,
.ql-snow .ql-toolbar:after {
  clear: both;
  content: '';
  display: table; }

.ql-snow.ql-toolbar button,
.ql-snow .ql-toolbar button {
  background: none;
  border: none;
  cursor: pointer;
  display: inline-block;
  float: left;
  height: 24px;
  outline: none;
  padding: 3px 5px;
  width: 28px; }

.ql-snow.ql-toolbar button svg,
.ql-snow .ql-toolbar button svg {
  float: left;
  height: 100%; }

.ql-snow.ql-toolbar input.ql-image[type=file],
.ql-snow .ql-toolbar input.ql-image[type=file] {
  display: none; }

.ql-snow.ql-toolbar button:hover,
.ql-snow .ql-toolbar button:hover,
.ql-snow.ql-toolbar button.ql-active,
.ql-snow .ql-toolbar button.ql-active,
.ql-snow.ql-toolbar .ql-picker-label:hover,
.ql-snow .ql-toolbar .ql-picker-label:hover,
.ql-snow.ql-toolbar .ql-picker-label.ql-active,
.ql-snow .ql-toolbar .ql-picker-label.ql-active,
.ql-snow.ql-toolbar .ql-picker-item:hover,
.ql-snow .ql-toolbar .ql-picker-item:hover,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected {
  color: #06c; }

.ql-snow.ql-toolbar button:hover .ql-fill,
.ql-snow .ql-toolbar button:hover .ql-fill,
.ql-snow.ql-toolbar button.ql-active .ql-fill,
.ql-snow .ql-toolbar button.ql-active .ql-fill,
.ql-snow.ql-toolbar .ql-picker-label:hover .ql-fill,
.ql-snow .ql-toolbar .ql-picker-label:hover .ql-fill,
.ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-fill,
.ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-fill,
.ql-snow.ql-toolbar .ql-picker-item:hover .ql-fill,
.ql-snow .ql-toolbar .ql-picker-item:hover .ql-fill,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-fill,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-fill,
.ql-snow.ql-toolbar button:hover .ql-stroke.ql-fill,
.ql-snow .ql-toolbar button:hover .ql-stroke.ql-fill,
.ql-snow.ql-toolbar button.ql-active .ql-stroke.ql-fill,
.ql-snow .ql-toolbar button.ql-active .ql-stroke.ql-fill,
.ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill,
.ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill,
.ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill,
.ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill,
.ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill,
.ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill {
  fill: #06c; }

.ql-snow.ql-toolbar button:hover .ql-stroke,
.ql-snow .ql-toolbar button:hover .ql-stroke,
.ql-snow.ql-toolbar button.ql-active .ql-stroke,
.ql-snow .ql-toolbar button.ql-active .ql-stroke,
.ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke,
.ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke,
.ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke,
.ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke,
.ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke,
.ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
.ql-snow.ql-toolbar button:hover .ql-stroke-mitter,
.ql-snow .ql-toolbar button:hover .ql-stroke-mitter,
.ql-snow.ql-toolbar button.ql-active .ql-stroke-mitter,
.ql-snow .ql-toolbar button.ql-active .ql-stroke-mitter,
.ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke-mitter,
.ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke-mitter,
.ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke-mitter,
.ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke-mitter,
.ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke-mitter,
.ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke-mitter,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke-mitter,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke-mitter {
  stroke: #06c; }

.ql-snow {
  box-sizing: border-box; }

.ql-snow * {
  box-sizing: border-box; }

.ql-snow .ql-hidden {
  display: none; }

.ql-snow .ql-out-bottom,
.ql-snow .ql-out-top {
  visibility: hidden; }

.ql-snow .ql-tooltip {
  position: absolute; }

.ql-snow .ql-tooltip a {
  cursor: pointer;
  text-decoration: none; }

.ql-snow .ql-formats {
  display: inline-block;
  vertical-align: middle; }

.ql-snow .ql-formats:after {
  clear: both;
  content: '';
  display: table; }

.ql-snow .ql-toolbar.snow,
.ql-snow .ql-stroke {
  fill: none;
  stroke: #444;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 2; }

.ql-snow .ql-stroke-mitter {
  fill: none;
  stroke: #444;
  stroke-mitterlimit: 10;
  stroke-width: 2; }

.ql-snow .ql-fill,
.ql-snow .ql-stroke.ql-fill {
  fill: #444; }

.ql-snow .ql-empty {
  fill: none; }

.ql-snow .ql-even {
  fill-rule: evenodd; }

.ql-snow .ql-thin,
.ql-snow .ql-stroke.ql-thin {
  stroke-width: 1; }

.ql-snow .ql-transparent {
  opacity: 0.4; }

.ql-snow .ql-direction svg:last-child {
  display: none; }

.ql-snow .ql-direction.ql-active svg:last-child {
  display: inline; }

.ql-snow .ql-direction.ql-active svg:first-child {
  display: none; }

.ql-snow .ql-editor h1 {
  font-size: 2em; }

.ql-snow .ql-editor h2 {
  font-size: 1.5em; }

.ql-snow .ql-editor h3 {
  font-size: 1.17em; }

.ql-snow .ql-editor h4 {
  font-size: 1em; }

.ql-snow .ql-editor h5 {
  font-size: 0.83em; }

.ql-snow .ql-editor h6 {
  font-size: 0.67em; }

.ql-snow .ql-editor a {
  text-decoration: underline; }

.ql-snow .ql-editor blockquote {
  border-left: 4px solid #ccc;
  margin-bottom: 5px;
  margin-top: 5px;
  padding-left: 16px; }

.ql-snow .ql-editor code,
.ql-snow .ql-editor pre {
  background-color: #f0f0f0;
  border-radius: 3px; }

.ql-snow .ql-editor pre {
  white-space: pre-wrap;
  margin-bottom: 5px;
  margin-top: 5px;
  padding: 5px 10px; }

.ql-snow .ql-editor code {
  font-size: 85%;
  padding-bottom: 2px;
  padding-top: 2px; }

.ql-snow .ql-editor code:before,
.ql-snow .ql-editor code:after {
  content: "\A0";
  letter-spacing: -2px; }

.ql-snow .ql-editor pre.ql-syntax {
  background-color: #23241f;
  color: #f8f8f2;
  overflow: visible; }

.ql-snow .ql-editor img {
  max-width: 100%; }

.ql-snow .ql-picker {
  color: #444;
  display: inline-block;
  float: left;
  font-size: 14px;
  font-weight: 500;
  height: 24px;
  position: relative;
  vertical-align: middle; }

.ql-snow .ql-picker-label {
  cursor: pointer;
  display: inline-block;
  height: 100%;
  padding-left: 8px;
  padding-right: 2px;
  position: relative;
  width: 100%; }

.ql-snow .ql-picker-label::before {
  display: inline-block;
  line-height: 22px; }

.ql-snow .ql-picker-options {
  background-color: #fff;
  display: none;
  min-width: 100%;
  padding: 4px 8px;
  position: absolute;
  white-space: nowrap; }

.ql-snow .ql-picker-options .ql-picker-item {
  cursor: pointer;
  display: block;
  padding-bottom: 5px;
  padding-top: 5px; }

.ql-snow .ql-picker.ql-expanded .ql-picker-label {
  color: #ccc;
  z-index: 2; }

.ql-snow .ql-picker.ql-expanded .ql-picker-label .ql-fill {
  fill: #ccc; }

.ql-snow .ql-picker.ql-expanded .ql-picker-label .ql-stroke {
  stroke: #ccc; }

.ql-snow .ql-picker.ql-expanded .ql-picker-options {
  display: block;
  margin-top: -1px;
  top: 100%;
  z-index: 1; }

.ql-snow .ql-color-picker,
.ql-snow .ql-icon-picker {
  width: 28px; }

.ql-snow .ql-color-picker .ql-picker-label,
.ql-snow .ql-icon-picker .ql-picker-label {
  padding: 2px 4px; }

.ql-snow .ql-color-picker .ql-picker-label svg,
.ql-snow .ql-icon-picker .ql-picker-label svg {
  right: 4px; }

.ql-snow .ql-icon-picker .ql-picker-options {
  padding: 4px 0px; }

.ql-snow .ql-icon-picker .ql-picker-item {
  height: 24px;
  width: 24px;
  padding: 2px 4px; }

.ql-snow .ql-color-picker .ql-picker-options {
  padding: 3px 5px;
  width: 152px; }

.ql-snow .ql-color-picker .ql-picker-item {
  border: 1px solid transparent;
  float: left;
  height: 16px;
  margin: 2px;
  padding: 0px;
  width: 16px; }

.ql-snow .ql-color-picker .ql-picker-item.ql-primary-color {
  margin-bottom: toolbarPadding; }

.ql-snow .ql-picker:not(.ql-color-picker):not(.ql-icon-picker) svg {
  position: absolute;
  margin-top: -9px;
  right: 0;
  top: 50%;
  width: 18px; }

.ql-snow .ql-picker.ql-header .ql-picker-label[data-label]:not([data-label=''])::before,
.ql-snow .ql-picker.ql-font .ql-picker-label[data-label]:not([data-label=''])::before,
.ql-snow .ql-picker.ql-size .ql-picker-label[data-label]:not([data-label=''])::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-label]:not([data-label=''])::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-label]:not([data-label=''])::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-label]:not([data-label=''])::before {
  content: attr(data-label); }

.ql-snow .ql-picker.ql-header {
  width: 98px; }

.ql-snow .ql-picker.ql-header .ql-picker-label::before,
.ql-snow .ql-picker.ql-header .ql-picker-item::before {
  content: 'Normal'; }

.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="1"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="1"]::before {
  content: 'Heading 1'; }

.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="2"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="2"]::before {
  content: 'Heading 2'; }

.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="3"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="3"]::before {
  content: 'Heading 3'; }

.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="4"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="4"]::before {
  content: 'Heading 4'; }

.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="5"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="5"]::before {
  content: 'Heading 5'; }

.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="6"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="6"]::before {
  content: 'Heading 6'; }

.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="1"]::before {
  font-size: 2em; }

.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="2"]::before {
  font-size: 1.5em; }

.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="3"]::before {
  font-size: 1.17em; }

.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="4"]::before {
  font-size: 1em; }

.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="5"]::before {
  font-size: 0.83em; }

.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="6"]::before {
  font-size: 0.67em; }

.ql-snow .ql-picker.ql-font {
  width: 108px; }

.ql-snow .ql-picker.ql-font .ql-picker-label::before,
.ql-snow .ql-picker.ql-font .ql-picker-item::before {
  content: 'Sans Serif'; }

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value=serif]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value=serif]::before {
  content: 'Serif'; }

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value=monospace]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value=monospace]::before {
  content: 'Monospace'; }

.ql-snow .ql-picker.ql-font .ql-picker-item[data-value=serif]::before {
  font-family: Georgia, Times New Roman, serif; }

.ql-snow .ql-picker.ql-font .ql-picker-item[data-value=monospace]::before {
  font-family: Monaco, Courier New, monospace; }

.ql-snow .ql-picker.ql-size {
  width: 98px; }

.ql-snow .ql-picker.ql-size .ql-picker-label::before,
.ql-snow .ql-picker.ql-size .ql-picker-item::before {
  content: 'Normal'; }

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value=small]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value=small]::before {
  content: 'Small'; }

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value=large]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value=large]::before {
  content: 'Large'; }

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value=huge]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value=huge]::before {
  content: 'Huge'; }

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value=small]::before {
  font-size: 10px; }

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value=large]::before {
  font-size: 18px; }

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value=huge]::before {
  font-size: 32px; }

.ql-snow .ql-color-picker.ql-background .ql-picker-item {
  background-color: #fff; }

.ql-snow .ql-color-picker.ql-color .ql-picker-item {
  background-color: #000; }

.ql-toolbar.ql-snow {
  border: 1px solid #ccc;
  box-sizing: border-box;
  font-family: 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
  padding: 8px; }

.ql-toolbar.ql-snow .ql-formats {
  margin-right: 15px; }

.ql-toolbar.ql-snow .ql-picker-label {
  border: 1px solid transparent; }

.ql-toolbar.ql-snow .ql-picker-options {
  border: 1px solid transparent;
  box-shadow: rgba(0, 0, 0, 0.2) 0 2px 8px; }

.ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label {
  border-color: #ccc; }

.ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options {
  border-color: #ccc; }

.ql-toolbar.ql-snow .ql-color-picker .ql-picker-item.ql-selected,
.ql-toolbar.ql-snow .ql-color-picker .ql-picker-item:hover {
  border-color: #000; }

.ql-toolbar.ql-snow + .ql-container.ql-snow {
  border-top: 0px; }

.ql-snow .ql-tooltip {
  background-color: #fff;
  border: 1px solid #ccc;
  box-shadow: 0px 0px 5px #ddd;
  color: #444;
  margin-top: 10px;
  padding: 5px 12px;
  white-space: nowrap; }

.ql-snow .ql-tooltip::before {
  content: "Visit URL:";
  line-height: 26px;
  margin-right: 8px; }

.ql-snow .ql-tooltip input[type=text] {
  display: none;
  border: 1px solid #ccc;
  font-size: 13px;
  height: 26px;
  margin: 0px;
  padding: 3px 5px;
  width: 170px; }

.ql-snow .ql-tooltip a.ql-preview {
  display: inline-block;
  max-width: 200px;
  overflow-x: hidden;
  text-overflow: ellipsis;
  vertical-align: top; }

.ql-snow .ql-tooltip a.ql-action::after {
  border-right: 1px solid #ccc;
  content: 'Edit';
  margin-left: 16px;
  padding-right: 8px; }

.ql-snow .ql-tooltip a.ql-remove::before {
  content: 'Remove';
  margin-left: 8px; }

.ql-snow .ql-tooltip a {
  line-height: 26px; }

.ql-snow .ql-tooltip.ql-editing a.ql-preview,
.ql-snow .ql-tooltip.ql-editing a.ql-remove {
  display: none; }

.ql-snow .ql-tooltip.ql-editing input[type=text] {
  display: inline-block; }

.ql-snow .ql-tooltip.ql-editing a.ql-action::after {
  border-right: 0px;
  content: 'Save';
  padding-right: 0px; }

.ql-snow .ql-tooltip[data-mode=link]::before {
  content: "Enter link:"; }

.ql-snow .ql-tooltip[data-mode=formula]::before {
  content: "Enter formula:"; }

.ql-snow .ql-tooltip[data-mode=video]::before {
  content: "Enter video:"; }

.ql-snow a {
  color: #06c; }

.ql-container.ql-snow {
  border: 1px solid #ccc; }

.editor-wrapper .ql-editor {
  height: inherit;
  max-height: inherit;
  overflow-y: inherit; }

.editor-wrapper .ql-toolbar.ql-snow {
  border: none;
  text-align: center;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: rgba(255, 255, 255, 0.8);
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12); }

.assistant-panel .card-item {
  border-radius: 0;
  margin-bottom: 8px; }

.assistant-panel span.bp3-popover-target {
  display: block; }

.tick text {
  font-size: 14px;
}

/*@font-face {
  font-family: myFirstFont;
  src: url(https://fonts.googleapis.com/css?family=Roboto:300,400,500);
}

body {
  font-family: myFirstFont !important;
}*/

.gm-style-iw-d {
  max-height: 200px !important;
  overflow: hidden !important;
  overflow-y: auto !important;
  opacity: 0.940;
}

.info-card:last-child {
  margin-bottom: 8px !important;
}

.info-card .title {
  padding: 8px 16px;
  background-color: #add9d6;
  font-size: 16px;
  font-weight: bold;
}

.info-card .desc {
  padding: 8px 16px;
  font-size: 16px;
}

.info-card .date {
  padding: 8px 16px;
  font-size: 14px;
  font-weight: bold;
}

.gm-style-iw.gm-style-iw-c {
  border: 2px solid #add9d6 !important;
  border-radius: 0 !important;
  background-color: #e6f0ef !important;
  color: #222 !important;
  opacity: 0.9;
}


.gm-style .gm-style-iw-t::after {
  background: #e6f0ef !important;
  z-index: -99 !important;
}

.gm-style .gm-style-iw-c {
  padding: 0 !important;
}


.tab-view {
  position: relative;
  width: 100%;
  height: 100%; }
  .tab-view .tabs {
    margin: 0;
    padding: 0;
    background: #f3f3f3;
    line-height: 26px;
    width: 100%;
    height: 26;
    color: #444;
    font-size: 13px;
    font-weight: normal; }
    .tab-view .tabs::before {
      content: "";
      display: block;
      position: absolute;
      border-bottom: 1px solid #cccccc;
      width: 100%;
      height: 0;
      top: 27px; }
    .tab-view .tabs li {
      display: inline-block;
      padding: 0 10px; }
      .tab-view .tabs li:hover {
        background: #eaeaea; }
      .tab-view .tabs li.selected {
        border-bottom: 2px solid #61a6f2; }
  .tab-view .content {
    position: absolute;
    top: 28px;
    bottom: 0;
    width: 100%;
    overflow: auto; }

.selection-area {
  background-color: rgba(255, 0, 0, 0.4) !important;
  z-index: 9999 !important; }

.user-form .form {
  box-shadow: 0 4px 12px rgba(75, 75, 75, 0.2);
  border: 1px solid #c6c6c6;
  background: #fff; }

.user-form .form {
  width: 380px;
  padding: 40px 40px;
  margin: 60px auto 0; }
  @media (max-width: 600px) {
    .user-form .form {
      width: 100%;
      padding: 40px 20px;
      margin: 0;
      border: none; } }
  .user-form .form header .logo {
    display: block;
    width: 64px;
    height: 64px;
    margin: 0 auto; }
  .user-form .form header h1 {
    margin: 0;
    color: #999;
    text-align: center;
    line-height: 64px;
    font-family: Palatino; }
  .user-form .form .form-content {
    margin-top: 20px; }
  .user-form .form .form-control {
    width: 100%;
    margin: 5px 0; }
  .user-form .form .tips {
    font-size: 14px;
    color: #757575; }

.user-form .footer {
  margin: 10px 16px;
  text-align: center;
  color: #999; }
  .user-form .footer .info {
    text-align: center; }

.user-form .privacy-policy {
  text-decoration: underline;
  font-weight: bolder;
  cursor: pointer; }
  .user-form .privacy-policy:hover {
    color: #38445e; }


.dashboard {
  color: #333;
  position: relative;
  header {
    position: relative;
    .logo {
      img {
        height: 75px;
      }
    }
    .actions {
      position: absolute;
      top: 0;
      right: 0;
    }
  }
  .main {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .quick-actions {
    display: flex;
    justify-content: space-around;
    margin: 40px auto;
    width: 360px;
  }
  footer {
    bottom: 10px;
    text-align: center;
    .copyright {
      color: #999;
    }
  }
}

$primary-color: #29abe2;
$side-nav-width: 0;
$sidebar-width: 300px;
// $statusbar-height: 24px;
$statusbar-height: 0;

%paper-like {
  box-shadow: 0 4px 12px rgba(75, 75, 75, 0.2);
  border: 1px solid #c6c6c6;
  background: #fff;
}

%md-paper {
  box-shadow:
      0px 1px 5px 0px rgba(0,0,0,0.2),
      0px 2px 2px 0px rgba(0,0,0,0.14),
      0px 3px 1px -2px rgba(0,0,0,0.12);
  background-color: #fff;
}

.ql-container.ql-snow .ql-editor, .page-content {
  font-size: 16px;
  font-family:
    // Latin fonts
    'Times New Roman', Arial, Helvetica,
    // 宋体
    'Source Han Serif', 'STSong', 'NSimSun', 'SimSun',
    // 仿宋
    'STFangsong', 'FangSong', 'FangSong_GB2312'
  ;
  strong {
    font-weight: 700;
  }
  h1 {
    font-size: 20px;
    line-height: 1.5;
  }
  h2 {
    font-size: 18px;
    line-height: 1.5;
  }
  h3 {
    font-size: 16px;
    line-height: 1.5;
  }
  p {
    font-size: 16px;
    line-height: 32px;
  }
  .quill-variable {
    color: #f44336;
    text-decoration-line: underline;
    text-decoration-style: dotted;
    &.filled {
      color: inherit;
      text-decoration-color: #ccc;
    }
    
  }
  table {
    border-collapse: collapse;
    border-spacing: 0px;
    width: 100%;
  }
  th,
  td {
    padding: 5px;
    border: 1px solid black;
    word-break: break-all;
  }
  tr {
    line-height: 1.5;
  }
  td:empty:after{
    content: "\00a0";
  }
}

.editor-wrapper .page-content {
  height: auto;
}
